<!--
 * @Author: 熊志伟
 * @Date: 2023-12-06 17:30:02
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-10-11 14:44:23
 * @FilePath: \dcy-web\apps\admin\layouts\default.vue
 * @Description: 默认layout
-->
<script lang="ts" setup>
</script>

<template>
  <a-layout class="wrapper">
    <a-layout>
      <a-layout-sider
        theme="dark" :width="220" class="h-[100vh]"
      >
        <div class="logo">
          <img class="nav-logo" src="https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/logo-new.png">
          <span>后台管理</span>
        </div>
        <Menu />
      </a-layout-sider>
      <a-layout-content>
        <a-layout-header>
          <ClientOnly>
            <nav-bar />
          </ClientOnly>
        </a-layout-header>
        <div class="layout-content-wrapper">
          <slot />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<style scoped lang="less">
.wrapper {
  height: 100vh;
  overflow-y: auto;

  :deep(.arco-layout-sider) {
    --at-apply: bg-[#2D2C88];
  }

  .logo{
    --at-apply: text-white relative;
    span{
      --at-apply: absolute right-[20px] top-50%;
      transform: translateY(-50%);
    }
  }
  .nav-logo {
    --at-apply: inline-block h-18 w-full object-contain;
  }

  .layout-content-wrapper {
    --at-apply: p-[16px];
    height: calc(100vh - 4.5rem);
    overflow: auto;
    :deep(.layout-content){
      --at-apply: rounded-[4px];
      .picker{
        --at-apply: cursor-pointer;
        .arco-picker-input input{
          --at-apply: cursor-pointer;
        }
      }
    }
  }
  :deep(.pagination){
    --at-apply: flex justify-end mt-[20px];
  }
  :deep(.menu-wrapper) {
    --at-apply: bg-[#2D2C88];

    .arco-menu-item{
      --at-apply: bg-[#2D2C88];
    }

    .arco-menu-inline-header{
      --at-apply: bg-[#2D2C88];
    }

    .arco-menu-item:hover{
      --at-apply: bg-[#6B6AFF];
    }
    .arco-menu-item.arco-menu-selected {
      background: #6B6AFF!important;
    }
  }
}
</style>
