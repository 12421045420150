/*
 * @Author: 熊志伟
 * @Date: 2023-12-11 09:13:07
 * @LastEditors: 熊志伟
 * @LastEditTime: 2024-09-02 16:09:53
 * @FilePath: \dcy-web\apps\admin\stores\modules\basic\index.ts
 * @Description: 基础模块
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { ProjectInfoPageResp } from '@dcyjs/materials/types/project'

interface BasicState {
  chatUnreadNum: number
  projectResourceList: ProjectInfoPageResp[]
}

const useProjectStore = defineStore('basic', {
  state: (): BasicState => ({
    chatUnreadNum: 0, // 未读聊天消息
    projectResourceList: [],
  }),

  getters: {},

  actions: {
    setInfo(partial: Partial<BasicState>) {
      this.$patch(partial)
    },
    setChatUnreadNum(val: number) {
      this.chatUnreadNum = val
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useProjectStore as any, import.meta.hot))

export default useProjectStore
