<script lang="ts" setup>
import { Message } from '@arco-design/web-vue'
import { clearAllCache } from '@dcyjs/utils/auth'
import { getToday } from '~/utils/today'

// import { clearToken } from '~/utils/auth'

const userStore = useUserStore()
// const router = useRouter()
// const { logout } = useUser()

// const { themeConfig } = storeToRefs(useSettingStore())

const today = ref(getToday())
const isShow = ref(false) // 修改密码

setInterval(() => {
  today.value = getToday()
}, 1000)

// 修改密码 start
function onEditPassword() {
  isShow.value = true
}
function onOpenModal() {
  isShow.value = false
}
// 修改密码 end

const toPersonalPage = () => {
  navigateTo('/personal')
}

const avatar = computed(() => {
  return userStore?.avatar || 'https://dcy-static.oss-cn-hangzhou.aliyuncs.com/images/teacher_avatar.png'
})

// const topMenu = computed(() => appStore.topMenu && appStore.menu)

function handleLogout() {
  // appStore.toggleTheme(false)
  Message.success('退出成功')
  // clearToken()
  clearAllCache()
  navigateTo('/login')
  // logout()
}

// async function switchRoles() {
//   const res = await userStore.switchRoles()
//   Message.success(res as string)
// }
// const toggleDrawerMenu = inject('toggleDrawerMenu') as () => void
</script>

<template>
  <div class="navbar">
    <!-- <div class="left-side">
      <a-space> -->
    <!-- <img
          alt="logo"
          src="//p3-armor.byteimg.com/tos-cn-i-49unhts6dw/dfdba5317c0c20ce20e64fac803d52bc.svg~tplv-49unhts6dw-image.image"
        > -->
    <!-- <a-typography-title
          :style="{ margin: 0 }"
          :heading="5"
          class="!text-[16px] !font-bold !color-[#6B6AFF]"
        >
          达成云管理平台
        </a-typography-title> -->
    <!-- <icon-menu-fold
          v-if="!topMenu && appStore.device === 'mobile'"
          style="font-size: 22px; cursor: pointer"
          @click="toggleDrawerMenu"
        /> -->
    <!-- </a-space>
    </div> -->
    <div class="center-side">
      <!-- <Menu v-if="topMenu" /> -->
      <div>
        今天是 {{ today }}
      </div>
    </div>
    <ul class="right-side">
      <li>
        <a-dropdown trigger="click">
          <div class="user-avatar">
            <a-avatar
              :size="32"
              :style="{ marginRight: '8px', cursor: 'pointer' }"
            >
              <img alt="avatar" :src="avatar">
            </a-avatar>
            <div class="mr-[10px]">
              {{ userStore.accountName }}
            </div>
            <icon-down />
          </div>
          <template #content>
            <a-doption>
              <a-space @click="toPersonalPage">
                <icon-user />
                <span>
                  账号管理
                </span>
              </a-space>
            </a-doption>
            <a-doption>
              <a-space @click="onEditPassword">
                <icon-lock />
                <span>
                  修改密码
                </span>
              </a-space>
            </a-doption>
            <a-doption>
              <a-space @click="handleLogout">
                <icon-export />
                <span>
                  退出
                </span>
              </a-space>
            </a-doption>
          </template>
        </a-dropdown>
      </li>
    </ul>
  </div>
  <edit-password-modal v-if="isShow" :show="isShow" @open-modal="onOpenModal" />
</template>

<style scoped lang="less">
  .navbar {
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.06);
    --at-apply: h-18;
  }
  .left-side {
    --at-apply: flex items-center pl-5 w-[220px];
  }
  .center-side {
    --at-apply: flex flex-1 items-center px-5;
  }
  .right-side {
    display: flex;
    padding-right: 20px;
    list-style: none;
    :deep(.locale-select) {
      border-radius: 20px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
    a {
      color: var(--color-text-1);
      text-decoration: none;
    }
    .nav-btn {
      border-color: rgb(var(--gray-2));
      color: rgb(var(--gray-8));
      font-size: 16px;
    }
    .trigger-btn,
    .ref-btn {
      position: absolute;
      bottom: 14px;
    }
    .trigger-btn {
      margin-left: 14px;
    }
  }

.user-avatar {
  --at-apply: flex items-center cursor-pointer;
}
.arco-dropdown-option{
  --at-apply: block;
  :deep(.arco-dropdown-option-content){
    --at-apply: block w-full;
    .arco-space{
      --at-apply: w-full;
    }
  }
}
</style>

<style lang="less">
  .message-popover {
    .arco-popover-content {
      margin-top: 0;
    }
  }
</style>
