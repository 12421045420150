/*
 * @Author: suk.bear suk.bear.suwu@gmail.com
 * @Date: 2024-01-25 10:57:22
 * @LastEditors: suk.bear suk.bear.suwu@gmail.com
 * @LastEditTime: 2024-01-25 10:57:31
 * @FilePath: /dcy-web/apps/admin/utils/today.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const checkTime = function (i: number) {
  if (i < 10)
    return `0${i}`
  return `${i}`
}

export function getToday() {
  const nowdate = new Date()
  const year = nowdate.getFullYear()
  const month = nowdate.getMonth() + 1
  const date = nowdate.getDate()
  const day = nowdate.getDay()
  const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const h = checkTime(nowdate.getHours())
  const m = checkTime(nowdate.getMinutes())
  const s = checkTime(nowdate.getSeconds())
  // h = checkTime(nowdate.getHours())
  // m = checkTime(nowdate.getMinutes())
  // s = checkTime(nowdate.getSeconds())
  return `${year}年${month}月${date}日` + ` ${week[day]} ${h}:${m}:${s}`
}

export function getWeek() {
  const nowdate = new Date()
  const day = nowdate.getDay()
  const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  return week[day]
}

export function getDate() {
  const nowdate = new Date()
  const year = nowdate.getFullYear()
  const month = nowdate.getMonth() + 1
  const date = nowdate.getDate()

  return `${year}年${month}月${date}日`
}

export function getTime() {
  const nowdate = new Date()
  const h = checkTime(nowdate.getHours())
  const m = checkTime(nowdate.getMinutes())
  const s = checkTime(nowdate.getSeconds())
  // h = checkTime(nowdate.getHours())
  // m = checkTime(nowdate.getMinutes())
  // s = checkTime(nowdate.getSeconds())
  return `${h}:${m}:${s}`
}
