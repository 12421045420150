<!--
 * @Author: huangliangmiao 1127255342@qq.com
 * @Date: 2023-12-11 16:31:02
 * @LastEditors: huangliangmiao 1127255342@qq.com
 * @LastEditTime: 2024-03-11 14:07:25
 * @FilePath: \dcy-teaching-spaced:\webCode\demoweb\dcy-admin\src\components\EditPassword.vue
 * @Description: 修改密码
-->
<script setup lang="ts">
import { Message } from '@arco-design/web-vue'
import { clearAllCache } from '@dcyjs/utils/auth'
import { rsaEncrypt } from '@dcyjs/utils/jsencrypt'
import { getPubKey, updatePasswordData } from '~/services'

// import { clearToken } from '~/utils/auth'

const props = defineProps<{
  show: boolean
}>()
const emit = defineEmits(['openModal'])
const router = useRouter()
// const userStore = useUserStore()
const params = ref({
  newPassword: '',
  confirmPassword: '',
  password: '',
})
// 保存
async function updatePassword() {
  const pubKey = await getPubKey()
  const password = await rsaEncrypt(pubKey, params.value.newPassword) || ''
  const paramsData = {
    newPassword: password,
    confirmPassword: password,
    password: await rsaEncrypt(pubKey, params.value.password) || '',
    identityCode: 'manger',
    username: localStorage.getItem('username'),
  }
  const data = await (updatePasswordData(paramsData))
  if (data) {
    Message.success({
      content: '密码成功修改成功，请重新登录！',
      duration: 2000,
    })
    emit('openModal', false)
    // clearToken()
    clearAllCache()
    router.push('/login')
  }
}
function onSubmit() {
  if (!check())
    return false
  updatePassword()
}
// 关闭弹窗
function onHandleCancel() {
  emit('openModal', false)
}
function check() {
  if (params.value.password === '') {
    Message.warning({
      content: '原密码不能为空！',
      duration: 2000,
    })
    return false
  }
  if (params.value.newPassword === '') {
    Message.warning({
      content: '新密码不能为空！',
      duration: 2000,
    })
    return false
  }
  if (params.value.confirmPassword === '') {
    Message.warning({
      content: '确认密码不能为空！',
      duration: 2000,
    })
    return false
  }
  if (params.value.newPassword !== params.value.confirmPassword) {
    Message.warning({
      content: '二次密码不一致！',
      duration: 2000,
    })
    return false
  }
  return true
}
</script>

<template>
  <a-modal
    :visible="props.show"
    width="800px"
    :footer="false"
    :mask-closable="false"
    @cancel="onHandleCancel"
  >
    <template #title>
      修改密码
    </template>
    <div>
      <div class="item">
        <span class="lable">原密码：</span>
        <a-input-password v-model="params.password" placeholder="请输入原密码" :max-length="20" allow-clear />
      </div>
      <div class="item">
        <span class="lable">新密码：</span>
        <a-input-password v-model="params.newPassword" placeholder="请输入新密码" :max-length="20" allow-clear />
      </div>
      <div class="item">
        <span class="lable">确认密码：</span>
        <a-input-password v-model="params.confirmPassword" placeholder="请再次确认密码" :max-length="20" allow-clear />
      </div>
      <div class="btns">
        <a-button type="primary" size="large" @click="onSubmit">
          保存
        </a-button>
        <a-button size="large" class="cancel" @click="onHandleCancel">
          取消
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<style lang="less" scoped>
.item{
  --at-apply: flex items-center mb-4;
  .lable{
    --at-apply: inline-block w-25 leading-9 text-right;
  }
  :deep(.arco-input-wrapper){
    --at-apply: w-60% h-9 bg-white border border-gray-300 rounded ml-[10px];
  }
}
.btns{
  --at-apply: mb-4 mt-35 text-center;
  .cancel{
    --at-apply: border-gray-300 border bg-white;
  }
}
:deep(.arco-btn){
  --at-apply: mx-6 w-20 rounded-lg w-25;
}
</style>
