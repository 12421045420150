<script lang="ts" setup>
import { MenuComponent } from '@dcyjs/materials'

const userStore = useUserStore()

const menuList = computed(() => {
  return userStore?.menuList
})
</script>

<template>
  <MenuComponent :menu-list="menuList" />
</template>
