<!--
 * @Author: 熊志伟
 * @Date: 2023-12-06 17:30:02
 * @LastEditors: 宋绍华
 * @LastEditTime: 2024-10-15 10:10:27
 * @FilePath: \dcy-web\packages\materials\components\MenuComponent\item.vue
 * @Description: 菜单子项
-->

<script lang="ts">
import { openWindow, regexUrl } from '@dcyjs/utils'
import type { MenuListResponse } from '../../types/user'

export default {
  name: 'MenuItem', // 给组件命名
  props: ['menu', 'mKey'],
  setup(props) {
    const menu = computed(() => {
      return props.menu
    })
    const mKey = computed(() => props.mKey)
    const basicStore = useBasicStore()

    const showDot = computed(() => {
      return menu.value.pathUrl === '/basic'
    })

    const showBadgeNumber = computed(() => {
      return menu.value.pathUrl === '/chat?roomType=1'
    })

    const showBookingDot = computed(() => {
      return (menu.value.pathUrl === '/basic' || ['/basic/booking', '/basic/booking-devices'].includes(menu.value.pathUrl)) && basicStore.hasBookingUnreadMsg
    })

    const basicCount = computed(() => { // 基础模块红点数量
      return basicStore.chatUnreadNum || showBookingDot.value ? 1 : 0
    })

    async function goto(item: MenuListResponse) {
      // Open external link
      if (regexUrl.test(item.pathUrl)) {
        openWindow(item.pathUrl)
        return
      }
      await navigateTo(item.pathUrl)
    }
    return {
      menu,
      goto,
      mKey,
      showDot,
      showBadgeNumber,
      basicStore,
      basicCount,
      showBookingDot,
    }
  },
}
</script>

<template>
  <template v-if="!menu.isHide">
    <a-sub-menu v-if="menu.children?.length > 0" :key="menu.id">
      <template v-if="menu?.icon" #icon>
        <i :class="`iconfont ${menu?.icon} color-[#fff] ml-[-5px]`" style="font-size: 16px;" />
      </template>
      <template #title>
        <a-badge v-if="showDot" :offset="[5, 0]" :count="basicCount" dot :dot-style="{ boxShadow: 'none' }">
          <span :class="menu.parentId ? 'font-400 ml-5px' : ''">{{ menu.name }} </span>
        </a-badge>
        <span v-else :class="menu.parentId ? 'font-400 ml-5px' : ''">{{ menu.name }} </span>
      </template>
      <menu-item v-for="item in menu.children" :key="item.id" :m-key="item.id" :menu="item" />
    </a-sub-menu>
    <a-menu-item v-else :key="menu.id!" @click="goto(menu)">
      <template v-if="menu?.icon" #icon>
        <i :class="`iconfont ${menu?.icon} color-[#fff]  ml-[-5px] `" style="font-size: 16px;" />
      </template>
      <a-badge v-if="showBadgeNumber" :count="basicStore.chatUnreadNum" :max-count="99" :offset="[23, 5]" :dot-style="{ height: '16px', lineHeight: '16px', boxShadow: 'none' }">
        <span style="color: #fff; font-size: 14px;" :class="menu.parentId ? 'font-400 ml-5px' : ''">
          {{ menu.name }}
        </span>
      </a-badge>
      <a-badge v-else-if="showBookingDot" :offset="[5, 0]" :count="1" dot :dot-style="{ boxShadow: 'none' }">
        <span style="color: #fff; font-size: 14px;">{{ menu.name }} </span>
      </a-badge>
      <span v-else style="color: #fff; font-size: 14px;" :class="menu.parentId ? 'font-400 ml-5px' : ''">
        {{ menu.name }}
      </span>
    </a-menu-item>
  </template>
</template>

<style lang="less" scoped>
.el-menu-item.is-active {
  font-weight: 700;
}

:deep(.arco-menu-icon) {
  margin-right: 10px !important;
  margin-left: 4px;
}

:deep(.arco-menu-icon-suffix > svg) {
  color: #fff !important;
}

:deep(.arco-menu-title) {
  --at-apply: flex-1;
}
</style>
